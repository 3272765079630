import React from 'react'
import { useLocation } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'
import { object, string } from 'yup'

import { Button, FormField, TextInput } from '@percent/lemonade'
import { Loader } from '@percent/partner-dashboard/common/components'
import { AuthForm } from '@percent/partner-dashboard/app/auth/AuthForm'
import { AuthSuccessText } from '@percent/partner-dashboard/app/auth/AuthSuccessText'
import { FieldError } from '@percent/partner-dashboard/common/components/fields/FieldError'
import { useMutation } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useTranslation } from 'react-i18next'
import { emailRegex } from '@percent/utility'

export function VerificationPrompt() {
  const { authService } = useServices()
  const [{ success, errorMessage, isLoading }, { apiFunc: verifyAccount }] = useMutation(authService.verifyAccount)
  const { search } = useLocation()
  const { t } = useTranslation()
  const hash = new URLSearchParams(search).get('userHash')

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: () =>
      object().shape({
        email: string()
          .required('Required')
          .matches(emailRegex, { message: t('errorMessage.invalidEmail') })
      }),
    onSubmit: () => {
      verifyAccount({ hash: hash as string })
    }
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } = formik

  return (
    <AuthForm heading={t('typography.requestVerificationEmail')} handleSubmit={handleSubmit}>
      {isLoading ? (
        <Loader />
      ) : success ? (
        <AuthSuccessText text={t('typography.checkYourEmail')} signIn />
      ) : (
        <FormikProvider value={formik}>
          <FormField
            label={t('form.emailAddress')}
            status={touched.email && errors.email ? 'danger' : 'default'}
            statusMessage={errors.email}
            data-testid="email"
          >
            <TextInput
              name="email"
              placeholder={t('form.enterEmailAddress')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
          </FormField>
          {errorMessage && <FieldError error={errorMessage} />}
          <Button type="submit" data-testid="auth-active-button">
            {t('button.sendVerificationEmail')}
          </Button>
        </FormikProvider>
      )}
    </AuthForm>
  )
}
