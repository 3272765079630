import { useMediaQuery } from '@mui/material'
import { use100vh } from 'react-div-100vh'

import { LayoutProps } from './Layout.types'
import styled from 'styled-components'
import { LayoutWrapper } from '../LayoutWrapper/LayoutWrapper'

export const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 600px) {
    padding: 48px 90px 0px 90px;
    width: auto;
    min-width: 448px;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0px 40px 72px rgba(7, 12, 24, 0.08);
  }
`

export function Layout({ children }: LayoutProps) {
  // return type is a number in a browser and null in Node environment.
  const height = use100vh() as number

  const isMobileScreen = useMediaQuery('(max-width: 600px)')

  const getMaxHeight = () => {
    if (!isMobileScreen) {
      return height > 745 ? '745px' : `${height - 20}px`
    }

    return 'auto'
  }

  const getMinHeight = () => {
    if (isMobileScreen) {
      return `${height}px`
    }

    return height > 745 ? '745px' : `${height - 20}px`
  }

  return (
    <LayoutWrapper>
      <StyledIconWrapper
        style={{
          minHeight: getMinHeight(),
          maxHeight: getMaxHeight(),
        }}
      >
        {children}
      </StyledIconWrapper>
    </LayoutWrapper>
  )
}
