import React, { ReactNode } from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import '../app.scss'

import { MuiTable } from './overrides'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { drawerWidth } from '@percent/partner-dashboard/app/dashboard/layout/Layout'

const theme = createTheme({
  palette: {
    primary: { main: lemonadeTheme.colors.primary400 },
    secondary: { main: lemonadeTheme.colors.neutral400 },
    text: {
      primary: lemonadeTheme.colors.neutral400
    },
    success: { main: lemonadeTheme.colors.success400 }
  },
  overrides: {
    MuiTableContainer: {
      root: {
        width: 'auto',
        padding: '20px 24px'
      }
    },
    MuiSnackbar: {
      anchorOriginTopCenter: {
        top: '50px !important',
        transform: 'translateX(-50%) !important'
      }
    },
    MuiTable,
    MuiTableCell: {
      root: {
        padding: '12px 0',
        borderBottom: 'none',
        fontSize: '14px'
      },
      footer: {
        border: 'none'
      }
    },
    MuiTab: {
      root: {
        '&.Mui-selected': {
          color: `${lemonadeTheme.colors.neutral400} !important`
        }
      }
    },
    MuiToolbar: {},
    MuiDrawer: {
      paperAnchorDockedLeft: {
        width: drawerWidth,
        borderRight: 'none',
        borderRadius: '0px !important',
        boxShadow: '0px 2px 16px rgba(5, 0, 56, 0.08) !important'
      }
    },
    MuiAppBar: {
      root: {
        flexDirection: 'row-reverse',
        backgroundColor: `${lemonadeTheme.colors.white} !important`,
        boxShadow: '0px 2px 16px rgba(5, 0, 56, 0.08)'
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '14px'
      }
    },
    MuiSelect: {
      root: {
        padding: '14px',
        fontSize: '14px'
      },
      select: {
        '&:focus': {
          backgroundColor: lemonadeTheme.colors.white
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: lemonadeTheme.colors.neutral400
        }
      }
    },
    MuiPaper: {
      root: {
        boxShadow: 'none !important',
        borderRadius: '8px !important'
      }
    },
    MuiTableRow: {
      root: {
        borderBottom: `1px solid ${lemonadeTheme.colors.gray80}`
      },
      footer: {
        borderBottom: 'none'
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '14px',
        backgroundColor: `${lemonadeTheme.colors.black}`,
        opacity: '0.9',
        borderRadius: '2px',
        padding: '12px 16px',
        position: 'absolute',
        width: '400px'
      }
    },
    MuiDialog: {
      paperWidthLg: {
        maxWidth: '100% !important',
        maxHeight: '100% !important',
        width: '100% !important',
        height: '100% !important',
        margin: '0 !important'
      }
    }
  },
  typography: {
    fontFamily: ['"MarkPro"'].join(','),
    h5: {
      fontWeight: 500
    },
    h6: {
      fontSize: 20
    },
    subtitle1: {
      fontSize: 18
    },
    subtitle2: {
      fontSize: 16
    },
    body1: {
      fontSize: 14
    },
    body2: {
      fontSize: 12
    },
    button: {
      textTransform: 'none'
    }
  }
})

export function Theme({ children }: { children?: ReactNode }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
