import { Divider, Typography } from '@material-ui/core'
import React from 'react'

import { useTranslation } from 'react-i18next'
import styles from './Footer.module.scss'

export function Footer() {
  const { t } = useTranslation()

  return (
    <footer className={styles.footer}>
      <Divider />
      <Typography variant="body2">
        <span role="img">©</span> {`${new Date().getFullYear()} ${t('typography.wapMadeWith')}`}
        &nbsp;
        <span className={styles.privacy}>
          <a href="https://poweredbypercent.com/legal" target="_blank" rel="noreferrer">
            {t('typography.privacyAndTerms')}
          </a>
        </span>
      </Typography>
    </footer>
  )
}
