export const fontFamilyName = 'MarkPro';

export const fontFaces = `
  @font-face {
    font-family: '${fontFamilyName}';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://fonts.poweredbypercent.com/MarkPro.woff2') format('woff2');
  }

  @font-face {
    font-family: '${fontFamilyName}';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('https://fonts.poweredbypercent.com/MarkProMedium.woff2') format('woff2');
  }

  @font-face {
    font-family: '${fontFamilyName}';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('https://fonts.poweredbypercent.com/MarkProBook.woff2') format('woff2');
  }

  @font-face {
    font-family: '${fontFamilyName}';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('https://fonts.poweredbypercent.com/MarkProBold.woff2') format('woff2');
  }
`;
