import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { FormikProvider, useFormik } from 'formik'
import { object, string } from 'yup'

import { AuthForm } from '../AuthForm'

import styles from './SignIn.module.scss'
import { Loader } from '@percent/partner-dashboard/common/components'
import { useMutation } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useAuthDispatch } from '@percent/partner-dashboard/common/hooks/useAuthDispatch/useAuthDispatch'
import { SET_AUTHORISED } from '@percent/partner-dashboard/context/auth'
import { useAuthState } from '@percent/partner-dashboard/common/hooks/useAuthState/useAuthState'
import { Button, Feedback, FormField, Spacer, TextInput } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { emailRegex } from '@percent/utility'

const REQUIRED_2FA_ERROR = 'auth/requires_two_fa_setup'
const REQUIRED_2FA_LOGIN = 'auth/requires_two_fa_login'

export function SignIn() {
  const { authService } = useServices()
  const dispatch = useAuthDispatch()
  const { isAuthorised } = useAuthState()
  const { push } = useHistory()

  useEffect(() => {
    if (isAuthorised) {
      push('/dashboard')
    }
  }, [isAuthorised, push])

  const signInAndUpdateUser = (signInDetails: { email: string; password: string }) =>
    authService
      .singIn({
        ...signInDetails,
        email: signInDetails.email.trim(),
        enforce: true
      })
      .then(() => {
        localStorage.authState = JSON.stringify({
          isAuthorised: true,
          isAuthorising: false
        })

        return dispatch({
          type: SET_AUTHORISED,
          payload: {}
        })
      })
      .catch(error => {
        if (error.response.status === 400 && error.response.data.error.code === REQUIRED_2FA_ERROR) {
          push('/signin/2fa-setup', {
            password: signInDetails.password
          })
        } else if (error.response.status === 400 && error.response.data.error.code === REQUIRED_2FA_LOGIN) {
          push('/signin/2fa', {
            password: signInDetails.password
          })
        } else {
          throw error
        }
      })
  const [{ errorMessage, error, isLoading }, { apiFunc: signInUser }] = useMutation(signInAndUpdateUser)
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: () =>
      object().shape({
        email: string().trim().required('Required').matches(emailRegex, 'Must be a valid email'),
        password: string().required('Required').min(8)
      }),
    onSubmit: (signInDetails: { email: string; password: string }) => {
      signInUser(signInDetails)
    }
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } = formik

  useEffect(() => {
    if (error?.code === 'auth/must_login_with_sso') {
      push(`/signin/sso?email=${values.email}&action=sso-redirect`)
    }
  }, [error, push, values.email])

  return (
    <AuthForm heading={t('typography.signIn')} handleSubmit={handleSubmit}>
      {isLoading ? (
        <Loader />
      ) : (
        <FormikProvider value={formik}>
          <FormField
            label={t('form.emailAddress')}
            status={touched.email && errors.email ? 'danger' : 'default'}
            statusMessage={errors.email}
            data-testid="email"
          >
            <TextInput
              name="email"
              placeholder={t('form.enterEmailAddress')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
          </FormField>
          <Spacer size={4} axis="vertical" />
          <FormField
            label={t('form.password')}
            status={touched.password && errors.password ? 'danger' : 'default'}
            statusMessage={errors.password}
            data-testid="password"
          >
            <TextInput
              type="password"
              name="password"
              placeholder={t('form.enterPassword')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
          </FormField>
          <Spacer size={4} axis="vertical" />
          {errorMessage && <Feedback variant="critical">{errorMessage}</Feedback>}
          <Spacer size={5} axis="vertical" />
          <Button type="submit" stretch size="large" data-testid="auth-active-button">
            {t('button.signIn')}
          </Button>
          <div className={styles.formLinks}>
            <Typography variant="body2">
              <span>
                <Link to="/forgot-password">{t('form.forgotPassword')}</Link>
              </span>
            </Typography>
            <span className={styles.signInSso}>
              <Link to="/signin/sso">{t('button.signInSso')}</Link>
            </span>
          </div>
        </FormikProvider>
      )}
    </AuthForm>
  )
}
