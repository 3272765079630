import { BadgeVariant, IconRegistry } from '@percent/lemonade'
import { LocaleKey } from '@percent/partner-dashboard/i18n/config'
import { OrganisationTypes } from '@percent/partner-dashboard/services/applications/applicationsService.types'

enum OrgTypes {
  na = 'na',
  pending = 'pending'
}

type OrganisationTypeBadgeVariant = {
  status: BadgeVariant
  icon?: keyof typeof IconRegistry
  text: LocaleKey
}

type getOrgTypesTypes = {
  organisationTypes?: OrganisationTypes[] | string[] | [] | null
  rejectedAt?: string | null
  organisationId?: string | null
}

export const getOrgTypes = ({ organisationTypes, rejectedAt, organisationId }: getOrgTypesTypes) => {
  if ((organisationTypes?.length === 0 && rejectedAt && !organisationId) || organisationTypes === null) {
    return [OrgTypes.na]
  }

  if (organisationTypes && organisationTypes.length >= 1) {
    return organisationTypes
  }

  return [OrgTypes.pending]
}

export const mapOrganisationTypes = (organisationType: string): OrganisationTypeBadgeVariant => {
  switch (organisationType) {
    case OrgTypes.pending:
      return {
        status: 'default',
        icon: 'clock',
        text: 'status.pending'
      }
    case OrgTypes.na:
      return {
        status: 'default',
        icon: undefined,
        text: 'status.notAvailable'
      }
    default:
      return {
        status: 'informative',
        icon: 'categories',
        text: `organisationTypes.${organisationType}` as LocaleKey
      }
  }
}
