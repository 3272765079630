import { ListItem, Typography } from '@material-ui/core'
import React from 'react'

import styles from './AuthForm.module.scss'
import { AuthProps } from './Auth.types'
import { ReactComponent as PercentLogo } from '@percent/partner-dashboard/common/assets/images/percentlogo.svg'
import { ReactComponent as PartnerDashboardIcon } from '@percent/partner-dashboard/common/assets/images/partner-dashboard-icon.svg'
import { useTranslation } from 'react-i18next'

export function AuthForm({ heading = '', children, handleSubmit, partnerLogo = false }: AuthProps) {
  const { t } = useTranslation()

  return (
    <div className={styles.formContainer}>
      <form onSubmit={handleSubmit} className={styles.form}>
        {partnerLogo ? (
          <ListItem className={styles.logoContainer}>
            <PartnerDashboardIcon />
            <Typography variant="body1">{t('menu.partnerDashboard')}</Typography>
          </ListItem>
        ) : (
          <PercentLogo />
        )}
        {heading && (
          <Typography variant="h5" color="secondary" className={styles.signInText}>
            {heading}
          </Typography>
        )}
        {children}
      </form>
    </div>
  )
}
