import React from 'react'
import { Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { AuthForm } from '../AuthForm'

import { Button, Spacer } from '@percent/lemonade'
import styles from './ExpiredSessionHandle.module.scss'
import LoggedInAnotherDevice from '@percent/partner-dashboard/common/assets/images/logged-another-device.png'
import { useTranslation } from 'react-i18next'

export function ExpiredSessionHandle() {
  const { t } = useTranslation()
  const history = useHistory()
  const redirectToSignIn = () => history.push('/signin')

  return (
    <AuthForm partnerLogo>
      <>
        <img className={styles.sessionImage} alt="auth-world-screen" src={LoggedInAnotherDevice} />
        <Typography variant="h5" color="secondary" className={styles.loggedInText}>
          {t('typography.sessionHasExpired')}
        </Typography>
        <Typography className={styles.loggedOutText} variant="body1">
          {t('typography.loggedOutFromThisSession')}
        </Typography>
        <Spacer size={5} axis="vertical" />
        <Button size="large" stretch data-testid="auth-active-button" onPress={redirectToSignIn}>
          {t('button.signIn')}
        </Button>
      </>
    </AuthForm>
  )
}
