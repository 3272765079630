import { TableCell, Typography, PopperPlacementType, TableRow } from '@material-ui/core'
import React, { useCallback, useMemo, useState } from 'react'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import { Badge, Button } from '@percent/lemonade'
import { PressEvent } from '@react-types/shared'
import { Donation } from '@percent/partner-dashboard/services/donation/donationService.types'
import { DateRangePopper } from '@percent/partner-dashboard/common/components/dateRange/DateRangePopper/DateRangePopper'
import { EllipsisTableCell, ErrorView, Table } from '@percent/partner-dashboard/common/components'
import { useAuthState, useDidMountEffect, useQueryList } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import styles from './DonationListTable.module.scss'
import { useCSVDownloader } from '@percent/partner-dashboard/common/hooks/useCSVDownloader/useCSVDownloader'
import { fileNameFunc } from '@percent/partner-dashboard/common/library/utility/utility'
import { dayJS, getFormattedDate } from '@percent/partner-dashboard/common/library/utility/date'
import { ArrayParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { formatAmount, formatMinorUnitsValueToFullNumberWithDecimals } from '@percent/utility'
import { DonationStatusTableCell } from './DonationStatusTableCell'
import { isString } from 'lodash'
import { useTranslation } from 'react-i18next'

enum DonationListStatusFilter {
  'ACTIVE' = 1,
  'RECEIVED_PAYMENT',
  'DISBURSED',
  'CANCELLED'
}

export function DonationListTable() {
  const {
    donationService,
    reportingService: { getDonationReport }
  } = useServices()
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLElement) | null>()
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false)
  const [placement, setPlacement] = useState<PopperPlacementType>('bottom-end')
  const [queryParams, setQueryParams] = useQueryParams({
    pageSize: withDefault(NumberParam, 10),
    startDate: StringParam,
    endDate: StringParam,
    status: ArrayParam,
    'expand[]': withDefault(StringParam, 'organisation')
  })
  const [{ data, totalResults, isLoading, errorMessage }, { query, nextPage, previousPage }] = useQueryList(
    donationService.getDonationList,
    {
      ...queryParams,
      status: queryParams.status?.filter(isString)
    }
  )

  const { currencyInfo, partner } = useAuthState()

  useDidMountEffect(
    () =>
      query({
        ...queryParams,
        status: queryParams.status?.filter(isString)
      }),
    [queryParams, query]
  )

  const [{ loading: csvDownloaderLoading, errorCSVMessage }, { fetchDonationCSV }] = useCSVDownloader({
    service: getDonationReport,
    fileName: fileNameFunc,
    successMessage: t('toast.donationList.successMessage')
  })

  const handleClick = (event: PressEvent) => {
    setAnchorEl(anchorEl ? null : (event.target as EventTarget & HTMLElement))
    setIsDatePickerOpened(prev => !prev)
    setPlacement('bottom-end')
  }

  const handleExport = () => {
    if (fetchDonationCSV) {
      fetchDonationCSV({
        startDate:
          (queryParams.startDate && dayJS(queryParams.startDate).utc().toDate()) || dayJS('2022-10-18').utc().toDate(),
        endDate: dayJS(queryParams.endDate).endOf('day').toDate(),
        status: queryParams?.status?.filter(isString)
      })
    }
  }

  const handleClearDate = useCallback(() => {
    setQueryParams({ startDate: undefined, endDate: undefined })
  }, [setQueryParams])

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setQueryParams({
      status:
        newValue === DonationListStatusFilter.ACTIVE
          ? ['ACTIVE', 'REQUESTED_PAYMENT']
          : [DonationListStatusFilter[newValue]]
    })
  }

  const tabs = useMemo(
    () => [
      t('table.all'),
      t('table.awaitingPayment'),
      t('table.receivedPayment'),
      t('table.disbursed'),
      t('table.cancelled')
    ],
    [t]
  )

  const toolTipTabs = useMemo(
    () => [
      '',
      t('tootTip.donationHeader.awaitingPayment'),
      t('tootTip.donationHeader.receivedPayment'),
      t('tootTip.donationHeader.disbursed'),
      t('tootTip.donationHeader.cancelled')
    ],
    [t]
  )

  const hasDonorData = data && data.length > 0 && data[0].email !== undefined
  const donorColumn = hasDonorData ? [{ id: 'header.donor', isSortable: false, props: { width: '20%' } }] : []

  const columns = [
    { id: 'header.donation', isSortable: false, props: { width: '12%' } },
    { id: 'header.recipient', isSortable: false, props: { width: '20%' } },
    ...donorColumn,
    { id: 'header.type', isSortable: false, props: { width: '16%' } },
    { id: 'header.status', isSortable: false, props: { width: '16%' } },
    { id: 'header.donationCreated', isSortable: false, props: { width: '16%' } }
  ]

  const dateRange = queryParams.startDate && queryParams.endDate

  if (errorMessage || errorCSVMessage) {
    return <ErrorView errorMessage={errorMessage || errorCSVMessage} />
  }

  return (
    <>
      <Table
        data={data}
        title={t('menu.donations')}
        filtersContent={
          (data?.length !== 0 || !!queryParams.startDate) && (
            <div className={styles.actionWrapper}>
              {' '}
              <Button
                size="small"
                type="submit"
                data-testid="show-date-range"
                endIcon="dropdown-arrow-down"
                onPress={(e: PressEvent) => handleClick(e)}
              >
                {(dateRange &&
                  `${dayJS(queryParams.startDate).format('ll')} - ${dayJS(queryParams.endDate).format('ll')}`) ||
                  t('button.dateAll')}
              </Button>
              {dateRange && (
                <Typography className={styles.clearText} onClick={handleClearDate}>
                  {t('typography.clear')}
                </Typography>
              )}
              <div className={styles.seperator} />
              {csvDownloaderLoading ? (
                <Button size="small" variant="secondary" data-testid="export-csv-loading">
                  {t('button.exporting')}
                </Button>
              ) : (
                <Button size="small" variant="secondary" data-testid="export-csv-btn" onPress={handleExport}>
                  {t('button.exportCsv')}
                </Button>
              )}
            </div>
          )
        }
        isLoading={isLoading}
        totalResults={totalResults}
        value={DonationListStatusFilter[queryParams.status?.[0] as keyof typeof DonationListStatusFilter] ?? 0}
        handleChange={handleTabChange}
        tabs={tabs}
        previousPage={previousPage}
        nextPage={nextPage}
        columns={columns}
        emptyTableText={
          (queryParams.startDate && t('typography.noDonationTextSelectedDates')) || t('typography.noDonationText')
        }
        emptyTableImageType="query"
        handleRequestSort={() => {
          // do nothing
        }}
        orderBy=""
        toolTipTabs={toolTipTabs}
      >
        {data?.map(
          (
            {
              id,
              amount,
              status,
              userId,
              createdAt,
              currencyCode,
              firstName,
              lastName,
              email,
              organisation,
              type,
              accountId
            }: Donation,
            index: number
          ) => {
            const donorName = firstName ? `${firstName}${lastName ? ' ' + lastName : ''}` : email ? email : userId
            return (
              <TableRow key={id}>
                <TableCell>
                  {formatAmount({
                    currencyCode,
                    value: formatMinorUnitsValueToFullNumberWithDecimals(
                      amount,
                      currencyInfo?.find(el => el.code === currencyCode)?.minorUnits as number
                    )
                  })}{' '}
                  {currencyCode}
                </TableCell>
                <TableCell>{organisation?.data?.name}</TableCell>
                {hasDonorData && <TableCell>{type === 'matched' ? partner?.name : donorName}</TableCell>}
                <TableCell>
                  <Badge variant="default">
                    {type === 'hosted' && accountId
                      ? t(`typography.donationType.onPlatform`)
                      : t(`typography.donationType.${type}`)}
                  </Badge>
                </TableCell>
                <TableCell className={styles.statusCell}>
                  <DonationStatusTableCell status={status} />
                </TableCell>
                <TableCell className={styles.dateCell}>{getFormattedDate(createdAt)}</TableCell>
                <EllipsisTableCell rawJsonData={data[index]} />
              </TableRow>
            )
          }
        )}
      </Table>
      <DateRangePopper
        open={isDatePickerOpened}
        anchorEl={anchorEl}
        placement={placement}
        setOpen={setIsDatePickerOpened}
        setAnchorEl={setAnchorEl}
        setPlacement={setPlacement}
        setQueryParams={setQueryParams}
        queryParams={queryParams}
      />
    </>
  )
}
