import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { DonationContainer } from '../donation/DonationContainer'
import { Home } from '../home/Home'
import { Key } from '../key/Key'
import { NonprofitsContainer } from '../nonprofits/NonprofitsContainer'
import { CategoryContainer } from '../category/CategoryContainer'
import { CategoryNonprofitsTable } from '../category/categoryNonprofitsTable/CategoryNonprofitsTable'
import { MyAccount } from '../myAccount/MyAccount'
import { UsersContainer } from '../users/UsersContainer'
import { VerificationsContainer } from '../verifications/VerificatonsContainer'
import { VerificationDetailsContainer } from '../verifications/VerificationDetailsContainer'
import { usePermissionCheck } from '@percent/partner-dashboard/common/hooks/useAllow/usePermissionCheck'
import { EmployeesContainer } from '../workplaceGiving/Employees/EmployeesContainer'
import { useFeatureFlag } from '@percent/partner-dashboard/common/hooks'

export function Routes() {
  const { userCan } = usePermissionCheck()
  const { workplaceGivingFeatureFlag } = useFeatureFlag()

  return (
    <Switch>
      <Route path="/my-account" exact component={MyAccount} />
      <Route path="/dashboard">
        <Home />
      </Route>
      <Route path="/keys">
        <Key />
      </Route>
      {userCan('viewDonations') && (
        <Route path="/donations">
          <DonationContainer />
        </Route>
      )}
      <Route path="/nonprofits">
        <NonprofitsContainer />
      </Route>
      <Route path="/users">
        <UsersContainer />
      </Route>
      <Route exact path="/categories" component={CategoryContainer} />
      <Route exact path="/categories/:categoryId" component={CategoryNonprofitsTable} />
      <Route exact path="/verifications" component={VerificationsContainer} />
      <Route exact path="/verifications/:verificationId" component={VerificationDetailsContainer} />
      <Route exact path="/applications">
        <Redirect to="/verifications" />
      </Route>
      {workplaceGivingFeatureFlag && (userCan('manageOwners') || userCan('manageAdmins')) && (
        <Route exact path="/workplace-giving/employees" component={EmployeesContainer} />
      )}
      <Redirect from="/applications/:applicationId" to="/verifications/:applicationId" />
      <Route path="*">
        <Redirect to="/dashboard" />
      </Route>
    </Switch>
  )
}
