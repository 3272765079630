import { Table as MantineTable } from '@mantine/core'
import { useTableContext } from '../context/table-provider'

export function TableHeader() {
  const { columns } = useTableContext()

  return (
    <MantineTable.Thead>
      <MantineTable.Tr>
        {columns.map((col) => (
          <MantineTable.Th
            key={col.id}
            style={{
              width: col?.props?.width,
            }}
          >
            {col.id}
          </MantineTable.Th>
        ))}
      </MantineTable.Tr>
    </MantineTable.Thead>
  )
}
