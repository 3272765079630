import { CardDashboard, CardContent } from '@percent/lemonade'
import { VerificationAgentDetailsCardProps } from './VerificationAgentDetailsCard.types'

import { useTranslation } from 'react-i18next'

export const VerificationAgentDetailsCard = ({
  agentName,
  agentEmail,
  language
}: VerificationAgentDetailsCardProps) => {
  const { t } = useTranslation()

  return (
    <CardDashboard title={t('typography.contactDetails')}>
      <CardContent title={t('typography.name')} testId="verification-agent-name">
        {agentName}
      </CardContent>
      <CardContent title={t('typography.email')} testId="verification-agent-email">
        {agentEmail}
      </CardContent>
      <CardContent title={t('typography.language')} testId="verification-agent-language">
        {language || t('typography.notAvailable')}
      </CardContent>
    </CardDashboard>
  )
}
