import { Grid } from '@material-ui/core'
import React from 'react'

import AuthScreen from '@percent/partner-dashboard/common/assets/images/auth_screen.png'
import { Footer } from '@percent/partner-dashboard/common/components/footer/Footer'
import styles from './AuthBackground.module.scss'
import { AuthBackgroundProps } from './Auth.types'

export function AuthBackground({ children }: AuthBackgroundProps) {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={styles.container}
    >
      <Grid item xs={12}>
        {children}
      </Grid>
      <img alt="auth-world-screen" src={AuthScreen} className={styles.image} />
      <Footer />
    </Grid>
  )
}
