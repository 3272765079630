import React from 'react'
import QRCode from 'qrcode.react'

import { useWizard } from '../Wizard/useWizard/useWizard'

import { Button, Spacer, Copy } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { SetupQRStepProps } from './SetupQRStep.types'
import { Loader } from '@percent/partner-dashboard/common/components'
import styles from './SetupQRStep.module.scss'

export function SetupQRStep({ token = '', isLoading, tokenUri }: SetupQRStepProps) {
  const { t } = useTranslation()
  const { setNextStep } = useWizard()

  return isLoading ? (
    <Loader />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h1 className={styles.header}>{t('2fa.setupAuthentication')}</h1>
        <p className={styles.text}>
          {t('2fa.scanAppPart1')} {t('2fa.authenticatorApp')}
          {t('2fa.scanAppPart2')}
        </p>
        <QRCode renderAs="svg" className={styles.qrCode} value={tokenUri ?? ''} />
        <p className={styles.subtext}>{t('2fa.cantScanQR')}</p>
        <div className={styles.copyWrapper}>
          <Button disabled>{token ?? ''}</Button>
          <Spacer size={2} />
          <Copy text={token} />
        </div>
        <Button onPress={setNextStep}>{t('button.next')}</Button>
      </div>
    </div>
  )
}
