import { StylesProvider } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'

import { AuthContextController } from '@percent/partner-dashboard/context/auth'
import { App } from './app/app-entry'
import { Theme } from './app/theme/Theme'
import { environment } from './config'
import { environments } from './constants/environments'
import { ServiceContextProvider } from './context/serviceContext/ServiceContextProvider'
import { WithAxios } from '@percent/partner-dashboard/common/HOC/withAxios/WithAxios'
import { logger } from '@percent/partner-dashboard/capabilities/logger'
import { Fallback } from '@percent/partner-dashboard/common/components'
import { LemonadeProvider } from '@percent/lemonade'
import { QueryParamProvider } from 'use-query-params'
import { IamContextController } from './context/iam/iamContext/IamContextController'
import { abilityDefinitions } from './context/iam/abilityDefinitions'
import { MantineProvider } from '@mantine/core'
import { CountriesContextController } from './context/countries/CountriesContextController'

if ([environments.PRODUCTION, environments.SANDBOX].includes(environment as string)) {
  Sentry.init({
    dsn: 'https://6495fb4ae40e4e478aa27062e73077d3@o128854.ingest.sentry.io/5379754',
    environment: process.env.NX_REACT_APP_ENV,
    autoSessionTracking: true,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0.33
  })
}

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <Router>
      <Sentry.ErrorBoundary fallback={<Fallback />} onError={(error: Error) => logger(error)}>
        <MantineProvider>
          <LemonadeProvider>
            <QueryParamProvider adapter={ReactRouter5Adapter}>
              <StylesProvider injectFirst>
                <ServiceContextProvider>
                  <AuthContextController>
                    <IamContextController rulesList={abilityDefinitions}>
                      <CountriesContextController>
                        <Theme>
                          <WithAxios>
                            <App />
                          </WithAxios>
                        </Theme>
                      </CountriesContextController>
                    </IamContextController>
                  </AuthContextController>
                </ServiceContextProvider>
              </StylesProvider>
            </QueryParamProvider>
          </LemonadeProvider>
        </MantineProvider>
      </Sentry.ErrorBoundary>
    </Router>
  </React.StrictMode>
)
