import { use100vh } from 'react-div-100vh'
import styled from 'styled-components'

import { LayoutWrapperProps } from './LayoutWrapper.types'

export const StyledIconWrapper = styled.div`
  display: flex;
  justify-items: center;
  align-items: end;
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  justify-content: center;
  @media (min-width: 600px) : {
    align-items: center;
  }
`

export function LayoutWrapper({ children }: Readonly<LayoutWrapperProps>) {
  const height = use100vh()

  return (
    <StyledIconWrapper
      style={{
        minHeight: `${height}px`,
      }}
    >
      {children}
    </StyledIconWrapper>
  )
}
