import { useTranslation } from 'react-i18next'
import { ChangeEvent, EventHandler, KeyboardEvent, useState } from 'react'
import {
  ButtonText,
  SearchInput,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableProvider,
  TableRow,
  TableSearchAndFilters,
  Tooltip
} from '@percent/lemonade'
import { formatMoney } from '@percent/utility'
import { ErrorView } from '@percent/partner-dashboard/common/components'
import { useAuthState } from '@percent/partner-dashboard/common/hooks'
import { EmployeesTableProps } from './EmployeesTable.types'
import styles from './EmployeesTable.module.scss'

export function EmployeesTable({
  data,
  isLoading,
  totalResults,
  errorMessage,
  nextPage,
  previousPage,
  queryParams,
  setQueryParams
}: Readonly<EmployeesTableProps>) {
  const [searchValue, setSearchValue] = useState<string>(queryParams?.filter || '')
  const { partner } = useAuthState()
  const { t } = useTranslation()

  const hasDonationWallet = partner?.scopes?.some(data => data.scopeName.includes('donation_wallet'))
  const hasDonationMatching = partner?.scopes?.some(data => data.scopeName.includes('donation_matching'))

  const columns = [
    { id: t('table.header.fullName'), props: { width: '20%' } },
    { id: t('table.header.email'), props: { width: '35%' } },
    { id: t('table.header.team'), props: { width: '15%' } },
    ...(hasDonationWallet ? [{ id: t('table.header.currentWalletBalance'), props: { width: '15%' } }] : []),
    ...(hasDonationMatching ? [{ id: t('table.header.matchingBalance'), props: { width: '10%' } }] : []),
    { id: t('table.header.actions'), props: { width: '5%' } }
  ]

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = event => {
    if (searchValue.length <= 255) {
      if (event.key === 'Enter') {
        setQueryParams?.({
          filter: (event.target as HTMLInputElement).value.trim()
        })
      }
    }

    if (event.key === 'Escape') {
      setQueryParams?.({
        filter: ''
      })
      return setSearchValue('')
    }

    return
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <TableProvider
      columns={columns}
      totalResults={totalResults}
      isLoading={isLoading || !data}
      illustration="no-results"
      emptyTableTitle={
        queryParams?.filter
          ? t('typography.noSearchEmployeesListTitle', {
              searchTerm: queryParams?.filter
            })
          : t('typography.noEmployeesListTitle')
      }
      emptyTableDescription={
        queryParams?.filter
          ? t('typography.noSearchEmployeesListDescription')
          : t('typography.noEmployeesListDescription')
      }
    >
      <TableSearchAndFilters>
        <SearchInput
          name="search"
          value={searchValue}
          onKeyDown={handleKeyPress}
          onChange={handleChange}
          handleClearValue={() => {
            setSearchValue('')
            setQueryParams?.({
              filter: ''
            })
          }}
          placeholder={t('table.employeesListSearchPlaceholder')}
          status={searchValue.length >= 255 ? 'danger' : 'default'}
          statusMessage={t('errorMessage.shouldNotExceed255')}
        />
      </TableSearchAndFilters>
      <Table>
        <TableBody>
          {data?.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.fullName || t('status.notAvailable')}</TableCell>
              <TableCell>
                <Tooltip content={row.email}>
                  <span className={styles.email}>{row.email}</span>
                </Tooltip>
              </TableCell>
              <TableCell>
                {row.team.length > 1 ? (
                  <Tooltip content={row.team.join(', ')}>
                    {t('typography.teams', {
                      teamCount: row.team.length
                    })}
                  </Tooltip>
                ) : row.team.length === 1 ? (
                  row.team
                ) : (
                  t('status.notAvailable')
                )}
              </TableCell>
              {hasDonationWallet && (
                <TableCell>
                  {row?.wallet
                    ? formatMoney(row.wallet.balance, { hideDecimalsIfZero: true })
                    : t('status.notAvailable')}
                </TableCell>
              )}
              {hasDonationMatching && (
                <TableCell>
                  {row?.matching
                    ? formatMoney(row.matching.total, { hideDecimalsIfZero: true })
                    : t('status.notAvailable')}
                </TableCell>
              )}
              <TableCell>
                <ButtonText>{t('button.more')}</ButtonText>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        totalResultsText={t('table.results', { count: totalResults || undefined })}
        previousPage={previousPage}
        nextPage={nextPage}
      />
    </TableProvider>
  )
}
