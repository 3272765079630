import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { CarouselProps } from './carousel.types'
import { Icon } from '../icon'

export const CarouselSlidesContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    padding: ${theme.sizes.s};
    margin: -${theme.sizes.s};
    overflow: auto hidden;
    gap: 32px;
    scrollbar-width: none;
    scroll-behavior: smooth;
    scroll-snap-stop: always;
    scroll-snap-type: x mandatory;
  `}
`

export const CarouselSlide = styled.div`
  ${() => `
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    scroll-snap-align: center;
  `}
`

export const NavbarWrapper = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.sizes.s};
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`

export const Title = styled.div`
  ${({ theme }) => {
    return `
    color: ${theme.text?.primary};
    font-size: ${theme.fontSizes.h5};
    font-weight: ${theme.fontWeights.semiBold};
    line-height: ${theme.sizes[8]};
  `
  }}
`

export const NavArrowsWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    gap: ${theme.sizes.xs}
  `}
`

export const NavArrow = styled.button`
  ${({ theme }) => `
    color: ${theme.colors.gray900};
    border-color: ${theme.colors.gray100};
    border-width: 1px;
    border-style: solid;
    border-radius: 50%;
    background: none;
    width: ${theme.sizes.l};
    min-width: ${theme.sizes.l};
    height: ${theme.sizes.l};
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover {
      color: ${theme.colors.primary};
    };
    &:focus {
      border-color: ${theme.colors.primary};
    };
    &:disabled {
      color: ${theme.colors.gray100};
      border-color: ${theme.colors.gray100};
    };
  `}
`

export function Carousel({ items, title, ...props }: CarouselProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [prevDisabled, setPrevDisabled] = useState<boolean>(true)
  const [nextDisabled, setNextDisabled] = useState<boolean>(true)

  const changeSlide = (index: number) => {
    setCurrentIndex(index)

    if (containerRef.current) {
      containerRef.current.scrollLeft = containerRef.current.clientWidth * index
    }
  }

  useEffect(() => {
    setPrevDisabled(currentIndex === 0)
    setNextDisabled(currentIndex === items.length - 1)
  }, [items, currentIndex])

  return (
    <div {...props}>
      {items?.length === 0 ? null : (
        <>
          <NavbarWrapper>
            <Title>{title}</Title>
            {items.length > 1 && (
              <NavArrowsWrapper role="navigation">
                <NavArrow
                  disabled={prevDisabled}
                  onClick={() => changeSlide(currentIndex - 1)}
                >
                  <Icon name="arrow-left" size={5} />
                </NavArrow>
                <NavArrow
                  disabled={nextDisabled}
                  onClick={() => changeSlide(currentIndex + 1)}
                >
                  <Icon name="arrow-right" size={5} />
                </NavArrow>
              </NavArrowsWrapper>
            )}
          </NavbarWrapper>
          <CarouselSlidesContainer ref={containerRef}>
            {items.map((item, index) => (
              <CarouselSlide key={index}>{item}</CarouselSlide>
            ))}
          </CarouselSlidesContainer>
        </>
      )}
    </div>
  )
}
