import React, { useEffect } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { useHistory, Link, useLocation } from 'react-router-dom'
import { Typography } from '@material-ui/core'

import { LocationState } from '../TwoFactorAuthentication.types'

import styles from './SignInWithTwoFA.module.scss'
import { Button, FormField, Spacer, TextInput } from '@percent/lemonade'
import { Loader, FieldError } from '@percent/partner-dashboard/common/components'
import { useMutation } from '@percent/partner-dashboard/common/hooks'
import { SET_AUTHORISED } from '@percent/partner-dashboard/context/auth'
import { useAuthDispatch } from '@percent/partner-dashboard/common/hooks/useAuthDispatch/useAuthDispatch'
import { useAuthState } from '@percent/partner-dashboard/common/hooks/useAuthState/useAuthState'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { AuthForm } from '@percent/partner-dashboard/app/auth/AuthForm'
import { useTranslation } from 'react-i18next'

export function SignWithTwoFA() {
  const dispatch = useAuthDispatch()
  const { isAuthorised } = useAuthState()
  const { authService } = useServices()
  const { t } = useTranslation()
  const { push } = useHistory()
  const { state } = useLocation<LocationState>()

  useEffect(() => {
    if (!state?.password) {
      push('/signin')
    }
  }, [state, push])

  useEffect(() => {
    if (isAuthorised) {
      push('/')
    }
  }, [isAuthorised, push])

  const [{ errorMessage, isLoading }, { apiFunc: signInUser, setErrorMessage }] = useMutation(
    authService.verifyOtpKey,
    ({ data: { data } }) => {
      localStorage.authState = JSON.stringify({
        isAuthorised: true,
        isAuthorising: false
      })

      dispatch({
        type: SET_AUTHORISED,
        payload: {
          user: data
        }
      })
    }
  )

  const formik = useFormik({
    initialValues: {
      token: ''
    },
    validationSchema: () =>
      yup.object().shape({
        token: yup
          .string()
          .required('Required')
          .matches(/^[0-9]+$/, t('errorMessage.onlyDigits'))
          .min(6, t('errorMessage.max6Digit'))
          .max(6, t('errorMessage.max6Digit'))
      }),
    onSubmit: ({ token }: { token: string }) => {
      signInUser({
        token
      })
    }
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } = formik

  useEffect(() => {
    if (values.token) {
      setErrorMessage('')
    }
  }, [values.token, setErrorMessage])

  return (
    <AuthForm heading="Two-step verification" handleSubmit={handleSubmit}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Typography className={styles.passwordText} variant="body1">
            {t('2fa.enterSixDigitCode')}
          </Typography>
          <FormikProvider value={formik}>
            <FormField
              label={t('form.enterSixDigit')}
              status={touched.token && errors.token ? 'danger' : 'default'}
              statusMessage={errors.token}
              data-testid="token"
            >
              <TextInput
                name="token"
                placeholder={t('form.placeholderSixDigit')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.token}
              />
            </FormField>
          </FormikProvider>
          {errorMessage && <FieldError error={errorMessage} />}
          <Spacer size={5} axis="vertical" />
          <Button type="submit" size="large" stretch data-testid="auth-active-button">
            {t('button.confirm')}
          </Button>
          <Typography variant="body2" className={styles.signInFooterText}>
            <span>
              <Link
                to={{
                  pathname: '/signin/2fa-recovery',
                  state: {
                    ...state
                  }
                }}
              >
                {t('2fa.useRecoveryCode')}
              </Link>
            </span>
          </Typography>
          <Typography variant="body2">
            <span>
              <Link to="/signin">{t('2fa.signInDifferentUser')}</Link>
            </span>
          </Typography>
        </>
      )}
    </AuthForm>
  )
}
