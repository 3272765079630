import React from 'react'
import styled, { css } from 'styled-components'
import { Menu as HeadlessMenu } from '@headlessui/react'
import { Icon } from '../icon'
import { MenuProps } from './menu.types'
import { Tooltip } from '../tooltip'

const StyledMenuWrapper = styled(HeadlessMenu.Items)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    border-radius: ${theme.borderRadii.medium};
    background: ${theme.colors.neutral.white};
    box-shadow: 0px 8px 32px rgba(33, 37, 53, 0.16);
    margin: ${theme.sizes.xxs} 0;
    padding: 0 ${theme.sizes.xs};
    outline: none;
    position: absolute;
    z-index: 1;
    background-color: white;
  `}
`

const StyledSection = styled.div<{ $hasSectionDivider: boolean }>`
  ${({ theme, $hasSectionDivider }) => css`
    outline: none;
    padding: ${theme.sizes.xs} 0;

    ${$hasSectionDivider &&
    css`
      border-top: 1px solid ${theme.colors.gray80};
    `}
  `}
`

const StyledMenuItem = styled.button<{ $active: boolean }>`
  ${({ theme, $active }) => css`
    all: unset;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: ${theme.sizes.xs};
    width: 100%;
    height: ${theme.sizes.l};
    padding: 0 ${theme.sizes.xs};
    border-radius: ${theme.borderRadii.small};
    transition: all ${theme.transitionTime} ease;
    font-size: ${theme.fontSizes.bodySmall};
    outline: none;

    ${$active &&
    css`
      background: ${theme.colors.gray70};
    `}

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  `}
`

export const Menu = ({ sections, children, onSelect }: MenuProps) => {
  return (
    <HeadlessMenu>
      {(props) => (
        <>
          <HeadlessMenu.Button as={children} {...props} />

          <StyledMenuWrapper>
            {sections.map((section, sectionIndex) => (
              <StyledSection $hasSectionDivider={sectionIndex > 0}>
                {section.items.map((item) => (
                  <HeadlessMenu.Item key={item.key}>
                    {({ active }) => {
                      if (item.shouldBeDisabled && item.disabledTooltipInfo) {
                        return (
                          <Tooltip content={item.disabledTooltipInfo}>
                            <StyledMenuItem
                              $active={active}
                              disabled={item.shouldBeDisabled}
                              onClick={() => onSelect(item.key)}
                            >
                              {item.icon ? (
                                <Icon
                                  name={item.icon}
                                  size={5}
                                  color="gray400"
                                />
                              ) : null}
                              {item.label}
                            </StyledMenuItem>
                          </Tooltip>
                        )
                      }
                      return (
                        <StyledMenuItem
                          $active={active}
                          disabled={item.shouldBeDisabled}
                          onClick={() => onSelect(item.key)}
                        >
                          {item.icon ? (
                            <Icon name={item.icon} size={5} color="gray400" />
                          ) : null}
                          {item.label}
                        </StyledMenuItem>
                      )
                    }}
                  </HeadlessMenu.Item>
                ))}
              </StyledSection>
            ))}
          </StyledMenuWrapper>
        </>
      )}
    </HeadlessMenu>
  )
}
