import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useClick,
} from '@floating-ui/react-dom-interactions'
import { useEffect, useMemo, useRef, useState } from 'react'
import { TooltipOptions } from '../tooltip.types'

export function useTooltip({
  placement = 'top',
  trigger = 'hover',
  autoClose = false,
}: TooltipOptions = {}) {
  const [open, setOpen] = useState(false)
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(8), flip(), shift()],
  })

  const context = data.context

  const hover = useHover(context, {
    move: true,
    enabled: trigger === 'hover',
  })
  const focus = useFocus(context, {
    enabled: true,
  })
  const click = useClick(context, {
    enabled: trigger === 'click',
  })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })

  const interactions = useInteractions([hover, click, focus, dismiss, role])

  useEffect(() => {
    if (trigger === 'click' && open && autoClose !== false) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(() => {
        setOpen(false)
      }, autoClose)
    }
  }, [open, autoClose])

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data]
  )
}
