import styled from 'styled-components'
import { Icon } from '../icon'
import { Tooltip } from '../tooltip'
import { CopyProps } from './copy.types'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const DEFAULT_AUTO_CLOSE_TIMEOUT = 1500

const CopyContainer = styled.div<{ reversed?: boolean }>`
  ${({ theme, reversed }) => `
    display: flex;
    align-items: center;
    gap: ${theme.sizes.xs};

    ${reversed && 'flex-direction: row-reverse;'}
  `}
`

const CopyButton = styled.button`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    gap: ${theme.sizes.xs};
    border-radius: ${theme.sizes.xs};
    border: 1px solid ${theme.colors.gray200};
    padding: 5px;
    background: none;

    :hover {
      svg {
        & path {
          fill: ${theme.colors.gray700};
        }
      }
    }
  `}
`

export function Copy({
  text,
  message = 'Copied',
  autoCloseTimeout = DEFAULT_AUTO_CLOSE_TIMEOUT,
  children,
  withIcon = true,
  className,
  reversed = false,
}: Readonly<CopyProps>) {
  return (
    <CopyToClipboard text={text}>
      <CopyContainer reversed={reversed}>
        {children}
        <Tooltip content={message} trigger="click" autoClose={autoCloseTimeout}>
          <CopyButton data-testid="copy-content-button" className={className}>
            {withIcon && <Icon name="copy" size={5} color="gray600" />}
          </CopyButton>
        </Tooltip>
      </CopyContainer>
    </CopyToClipboard>
  )
}
