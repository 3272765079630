import { throttle } from 'lodash'

/**
 * Throttles an async function in a way that can be awaited.
 * By default throttle doesn't return a promise for async functions unless it's invoking them immediately.
 * See https://github.com/lodash/lodash/issues/4700 for details.
 * @param func async function to throttle calls for.
 * @param wait same function as lodash.throttle's wait parameter.
 *             Call this function at most this often.
 * @param options same options as lodash.throttle's options parameter.
 *             Set leading or trailing option to false.
 * @returns a promise which will be resolved/ rejected only if the function is executed, with the result of the underlying call.
 */
export const throttleAsync = <F extends (...args: any[]) => Promise<any>>(
  func: F,
  wait?: number,
  options = {}
) => {
  const throttled = throttle(
    (resolve, reject, args: Parameters<F>) => {
      func(...args)
        .then(resolve)
        .catch(reject)
    },
    wait,
    options
  )
  return (...args: Parameters<F>): ReturnType<F> =>
    new Promise((resolve, reject) => {
      throttled(resolve, reject, args)
    }) as ReturnType<F>
}
