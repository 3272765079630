import { Badge, Spacer } from '@percent/lemonade'
import { getOrgTypes, mapOrganisationTypes } from '@percent/partner-dashboard/common/library/utility/getOrgTypes'
import { useTranslation } from 'react-i18next'
import { OrgansiationTypesBadgesProps } from './OrganisationTypesBadges.types'
import { Fragment } from 'react'
import { LocaleKey } from '@percent/partner-dashboard/i18n/config'

export const OrganisationTypesBadges = ({
  organisation,
  validationRequest,
  organisationId
}: OrgansiationTypesBadgesProps) => {
  const { t } = useTranslation()

  const organisationTypes = organisation?.types || validationRequest?.organisationTypes

  return (
    <div>
      {getOrgTypes({
        organisationTypes,
        organisationId,
        rejectedAt: validationRequest?.rejectedAt
      }).map(orgType => (
        <Fragment key={orgType}>
          <Badge
            variant={mapOrganisationTypes(orgType)?.status}
            icon={mapOrganisationTypes(orgType)?.icon}
            key={`org-types-${validationRequest?.id}`}
          >
            {t(mapOrganisationTypes(orgType)?.text as LocaleKey)}
          </Badge>
          <Spacer size={1} axis="horizontal" />
        </Fragment>
      ))}
    </div>
  )
}
