import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Spinner } from '@percent/lemonade'
import { AuthForm } from '../AuthForm'

import { useAuthDispatch } from '@percent/partner-dashboard/common/hooks/useAuthDispatch/useAuthDispatch'
import { SET_AUTHORISED } from '@percent/partner-dashboard/context/auth'
import { useAuthState } from '@percent/partner-dashboard/common/hooks/useAuthState/useAuthState'

export const SSOGateway: React.FC = () => {
  const { push } = useHistory()

  const dispatch = useAuthDispatch()
  const { isAuthorised } = useAuthState()

  useEffect(() => {
    if (isAuthorised) {
      push('/dashboard')
    }
  }, [isAuthorised, push])

  useEffect(() => {
    dispatch({
      type: SET_AUTHORISED
    })
    localStorage.authState = JSON.stringify({
      isAuthorised: true,
      isAuthorising: false
    })
  }, [dispatch])

  return (
    <AuthForm>
      <div style={{ marginTop: '8rem' }}>
        <Spinner size={14} />
      </div>
    </AuthForm>
  )
}
