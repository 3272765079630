import { Combobox, Listbox } from '@headlessui/react'
import { RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { Option, OptionGroup } from '../option'
import { SelectOption, SelectOptionGroup } from '../option.types'

export const useSelect = (data: {
  options: (SelectOption | SelectOptionGroup)[]
  searchable?: boolean
  searchInDropDown?: boolean
  placeholder?: string
  defaultValue?: SelectOption
  noResultsFoundText?: string
  showPrefixForSelectedOption?: boolean
}) => {
  const {
    options,
    searchable,
    searchInDropDown,
    placeholder,
    defaultValue,
    noResultsFoundText = 'No results found',
    showPrefixForSelectedOption,
  } = data

  const [searchQuery, setSearchQuery] = useState('')

  const [selectedOption, setSelectedOption] = useState<
    SelectOption | undefined
  >(defaultValue)

  const inputRef = useRef<HTMLInputElement>() as RefObject<HTMLInputElement>

  useEffect(() => {
    setSearchQuery('')
  }, [selectedOption])

  useEffect(() => {
    if (defaultValue) {
      setSelectedOption(defaultValue)
    }
  }, [defaultValue])

  /**
   * Responsible for rendering and managing the state of results
   * list based on search query.
   */
  const renderElements = useMemo(() => {
    if (!options.length) {
      return <div style={{ padding: '1rem' }}>{noResultsFoundText}</div>
    }

    const optionMatchesSearchQuery = (option: SelectOption) => {
      if (!searchQuery || !searchable) {
        return true
      }

      return [option.label, option.description, option.value]
        .join(' ')
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    }

    const searchQueryFilter = (option: SelectOption) => {
      if (!searchQuery) return true
      return optionMatchesSearchQuery(option)
    }

    const ListType = searchable ? Combobox : Listbox

    const renderOption = (option: SelectOption) => {
      return (
        <ListType.Option
          key={option.value}
          data-testid={option.value}
          value={option}
          style={{
            listStyle: 'none',
            marginLeft: 0,
            paddingLeft: 0,
            border: 'none',
          }}
        >
          {({ active }) => <Option {...option} isActive={active}></Option>}
        </ListType.Option>
      )
    }

    const resultsMap = options
      .map((opt) => {
        if (Object.keys(opt).includes('title')) {
          const subOptionCollection = (opt as SelectOptionGroup).options.filter(
            searchQueryFilter
          )

          if (subOptionCollection.length === 0) return null

          const optionTitle = (opt as SelectOptionGroup).title

          return (
            <OptionGroup key={optionTitle} title={optionTitle}>
              {subOptionCollection.map((subOpt) => {
                return renderOption(subOpt)
              })}
            </OptionGroup>
          )
        } else {
          const castedOption = opt as SelectOption
          if (optionMatchesSearchQuery(castedOption)) {
            return renderOption(castedOption)
          }
          return null
        }
      })
      .filter((result) => result !== null)

    if (resultsMap.length === 0) {
      return <div style={{ padding: '1rem' }}>{noResultsFoundText}</div>
    }

    return resultsMap
  }, [options, searchable, noResultsFoundText, searchQuery])

  const inputProps = {
    disabled: !searchable,
    ref: inputRef,
    placeholder: placeholder,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
      setSearchQuery(e.target.value),
    displayValue: (selectedOption: SelectOption) =>
      searchInDropDown ? '' : selectedOption?.label || '',
  }

  const triggerProps = {
    onClick: () => {
      inputRef.current?.focus()
    },
  }

  // Used in preventing selection using spacebar when searching.
  const appendToSearch = (key: string) => {
    if (!inputRef.current) return
    inputRef.current.value = inputRef.current.value + key
  }

  return {
    triggerProps,
    value: selectedOption,
    inputProps,
    renderElements,
    setValue: (v: SelectOption) => {
      setSelectedOption(v)
    },
    appendToSearch,
    showPrefixForSelectedOption,
    afterLeave: () => setSearchQuery(''),
  }
}
