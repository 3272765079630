import { Loader, Spacer } from '@percent/lemonade'
import { VerificationAgentDetailsCard } from './VerificationAgentDetailsCard/VerificationAgentDetailsCard'
import { VerificationDetailsProps } from './VerificationDetails.types'
import { VerificationSummaryCard } from './VerificationSummaryCard/VerificationSummaryCard'
import { VerificationOrganisationDetailsCard } from './VerificationOrganisationDetails/VerificationOrganisationDetailsCard'
import { VerificationDocumentsCard } from './VerificationDocumentsCard/VerificationDocumentsCard'
import { VerificationPartnerFieldsCard } from './VerificationPartnerFields/VerificationPartnerFieldsCard'
import { ErrorView } from '@percent/partner-dashboard/common/components'
import { ValidationRequestRejectionReasonCodes } from '@percent/partner-dashboard/services/applications/applicationsService.types'
import { VerificationMetadataCard } from './VerificationMetadataCard/VerificationMetadataCard'

export function VerificationDetails({
  data,
  documents,
  organisation,
  isLoadingValidationSubmissionDocuments,
  isLoading,
  isLoadingOrganisation,
  errorMessage
}: Readonly<VerificationDetailsProps>) {
  if ((isLoading || isLoadingValidationSubmissionDocuments) && !data) return <Loader />

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  const hasPartnerFields = !!Object.keys(data.partnerFields).length

  const hasNoEligibleOrganisationType =
    !!organisation &&
    data?.validationRequest?.rejectionReasonCode === ValidationRequestRejectionReasonCodes.NotEligible &&
    !data.configuration?.allowedOrganisations?.types?.some(item => organisation?.types.includes(item))

  return (
    <div data-testid="verification-details">
      <VerificationSummaryCard
        verification={data}
        organisation={organisation}
        hasNoEligibleOrganisationType={hasNoEligibleOrganisationType}
      />

      {data?.agentVerification && (
        <>
          <Spacer size={8} axis="vertical" />
          <VerificationAgentDetailsCard
            agentName={`${data.agentVerification.firstName} ${data.agentVerification.lastName}`}
            agentEmail={data.agentVerification.email}
            language={data.agentVerification.language}
          />
        </>
      )}

      <Spacer size={8} axis="vertical" />
      <VerificationOrganisationDetailsCard
        organisation={organisation}
        isLoading={isLoadingOrganisation}
        validationRequest={data.validationRequest}
        eligibility={data.eligibility}
        hasNoEligibleOrganisationType={hasNoEligibleOrganisationType}
      />

      {hasPartnerFields && (
        <>
          <Spacer size={8} axis="vertical" />
          <VerificationPartnerFieldsCard partnerFields={data.partnerFields} />
        </>
      )}

      <Spacer size={8} axis="vertical" />
      <VerificationDocumentsCard documents={documents} isLoading={isLoadingValidationSubmissionDocuments} />

      {data.metadata && !!Object.keys(data.metadata).length && (
        <>
          <Spacer size={8} axis="vertical" />
          <VerificationMetadataCard metadata={data.metadata} />
        </>
      )}
    </div>
  )
}
