export const styledVariant =
  (prop: string, styles: Record<string, unknown>, fallback = '') =>
  (componentProps: unknown = {}): string => {
    // TODO make styledVariant a typed function with generics
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore disable errors here as we don't know what "componentProps" is
    const requestedVariantValue = componentProps[prop];
    const variantStyles = styles[requestedVariantValue];
    const fallbackStyles = styles[fallback];

    return (variantStyles || fallbackStyles || '') as string;
  };
