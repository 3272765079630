import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { FormikProvider, useFormik } from 'formik'
import { object, string } from 'yup'

import { AuthForm } from '../AuthForm'

import { Loader } from '@percent/partner-dashboard/common/components'
import { useMutation } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useAuthState } from '@percent/partner-dashboard/common/hooks/useAuthState/useAuthState'
import { Button, Feedback, FormField, Spacer, TextInput } from '@percent/lemonade'
import { useQueryParam } from 'use-query-params'
import { useTranslation } from 'react-i18next'
import { emailRegex } from '@percent/utility'

export function SSO() {
  const { authService } = useServices()
  const { t } = useTranslation()
  const { isAuthorised } = useAuthState()
  const { push } = useHistory()
  const [awaitingRedirect, setAwaitingRedirect] = useState(false)

  const [emailQueryParam, setEmailQueryParam] = useQueryParam('email')
  const [formLoadAction] = useQueryParam('action')
  const [customMessage, setCustomMessage] = useState('')

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: () =>
      object().shape({
        email: string().trim().required('Required').matches(emailRegex, 'Must be a valid email')
      }),
    onSubmit: (ssoDetails: { email: string }) => {
      ssoAuth(ssoDetails)
    }
  })

  useEffect(() => {
    if (isAuthorised) {
      push('/dashboard')
    }

    if (emailQueryParam && !formik.values.email) {
      formik.setFieldValue('email', emailQueryParam)

      if (formLoadAction && formLoadAction === 'sso-redirect') {
        setCustomMessage(t('typography.ssoSignInRequired'))
        setEmailQueryParam(undefined)
      }
    }
  }, [isAuthorised, push, emailQueryParam, formik, formLoadAction, t, setEmailQueryParam])

  const ssoUserAndUpdate = async (ssoDetails: { email: string }) => {
    setAwaitingRedirect(true)
    try {
      const response = await authService.sso({
        email: ssoDetails.email.trim()
      })

      const { loginUrl } = response.data.data
      setAwaitingRedirect(true)

      window.location.href = loginUrl

      return response
    } catch (error) {
      if (error.response.data.error.code === 'sso/partner_not_configured')
        setCustomMessage(t('errorMessage.noSSOConfiguration'))
      setAwaitingRedirect(false)
    }
    return null
  }

  const [{ errorMessage }, { apiFunc: ssoAuth }] = useMutation(ssoUserAndUpdate)

  useEffect(() => {
    if (errorMessage) {
      setAwaitingRedirect(false)
    }
  }, [errorMessage])

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } = formik

  return (
    <AuthForm heading={t('typography.signInSso')} handleSubmit={handleSubmit}>
      {awaitingRedirect ? (
        <Loader />
      ) : (
        <FormikProvider value={formik}>
          <FormField
            label={t('form.emailAddress')}
            status={touched.email && errors.email ? 'danger' : 'default'}
            statusMessage={errors.email || errorMessage}
            data-testid="email"
          >
            <TextInput
              name="email"
              placeholder={t('form.enterEmailAddress')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
          </FormField>
          <Spacer size={2} axis="vertical" />
          {(errorMessage || customMessage) && <Feedback variant="critical">{errorMessage || customMessage}</Feedback>}
          <Spacer size={8} axis="vertical" />
          <Button type="submit" stretch size="large" data-testid="auth-active-button">
            {t('button.signInSso')}
          </Button>
        </FormikProvider>
      )}
    </AuthForm>
  )
}
