import React, { useRef } from 'react'
import { theme } from '@percent/lemonade'
import styled from 'styled-components'
import { FullPageFormLayoutProps } from './full-page-form-layout.types'

const StyledWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${theme.colors.primarySurface};
  padding-left: ${theme.sizes[6]};
  padding-right: ${theme.sizes[6]};
  padding-bottom: ${theme.sizes[8]};
  @media (min-width: 1024px) {
    padding-left: 18%;
    padding-right: 18%;
  }
  @media (min-width: 1250px) {
    padding-left: 22%;
    padding-right: 22%;
  }
`

const StyledHeaderWrapper = styled.div`
  z-index: 91;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: ${theme.sizes[6]} ${theme.sizes[10]};
  background-color: ${theme.colors.white};
  border-bottom: 2px solid ${theme.colors.gray90};
`

const StyledContentWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  border-bottom-left-radius: ${theme.sizes[2]};
  border-bottom-right-radius: ${theme.sizes[2]};
  padding: ${theme.sizes[8]};
  background-color: ${theme.colors.white};

  @media (min-width: 1024px) {
    padding: ${theme.sizes[10]};
  }
`

export const FullPageFormLayout = ({
  actionsHeader,
  children,
}: FullPageFormLayoutProps) => {
  const headerWrapper = useRef<HTMLDivElement | null>(null)
  const headerWrapperHeight = useRef(headerWrapper.current?.offsetHeight || 80)

  return (
    <StyledWrapper
      data-testid="full-page-form-wrapper"
      style={{
        paddingTop: `${headerWrapperHeight.current}px`,
      }}
    >
      <StyledHeaderWrapper
        data-testid="full-page-form-header-wrapper"
        ref={headerWrapper}
      >
        {actionsHeader}
      </StyledHeaderWrapper>
      <StyledContentWrapper data-testid="full-page-form-content-wrapper">
        {children}
      </StyledContentWrapper>
    </StyledWrapper>
  )
}
