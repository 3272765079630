import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { AuthBackground } from '../AuthBackground'
import { SetPassword } from '../setPassword/SetPassword'
import { ForgotPassword } from '../forgotPassword/ForgotPassword'
import { SignIn } from '../signIn/SignIn'
import { ExpiredSessionHandle } from '../expiredSessionHandle/ExpiredSessionHandle'
import { SignWithTwoFA } from '../signIn/TwoFactorAuthentication/SignInWithTwoFA/SignWithTwoFA'
import { SignInWithRecoveryCodeContainer } from '../signIn/TwoFactorAuthentication/SignInWithRecoveryCode/SignInWithRecoveryCodeContainer'
import { SSO } from '../sso/SSO'

import { VerificationPrompt } from '@percent/partner-dashboard/app/verification/VerificationPrompt'
import { SSOGateway } from '../sso/SSOGateway'

export function AuthRoutes() {
  return (
    <AuthBackground>
      <Switch>
        <Route path="/signin/2fa-recovery">
          <SignInWithRecoveryCodeContainer />
        </Route>
        <Route path="/signin/2fa">
          <SignWithTwoFA />
        </Route>
        <Route path="/signin/sso/gateway">
          <SSOGateway />
        </Route>
        <Route path="/signin/sso">
          <SSO />
        </Route>
        <Route path="/signin">
          <SignIn />
        </Route>
        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route path="/set-password">
          <SetPassword />
        </Route>
        <Route path="/account-verification">
          <VerificationPrompt />
        </Route>
        <Route exact path="/expired-session">
          <ExpiredSessionHandle />
        </Route>
        <Route path="*">
          <Redirect to="/signin" />
        </Route>
      </Switch>
    </AuthBackground>
  )
}
