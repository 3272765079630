import styled, { css } from 'styled-components'
import { Icon } from '../icon'
import { Text } from '../text'
import { PaginationProps } from './pagination.types'
import { PaginationItem } from './pagination-item'
import { Pages } from './pages'
import { Compact } from './compact'

const StyledPagination = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.sizes.xs};
  `}
`

const TextShift = styled.div`
  transform: translateY(-2px);
`

const MAX_PAGES = 5
const DEFAULT_PER_PAGE = 50

export const Pagination = ({
  currentPage = 1,
  onPageChange,
  totalRecords,
  itemsPerPage,
}: PaginationProps) => {
  const perPage = itemsPerPage || DEFAULT_PER_PAGE
  const totalPages = Math.ceil(totalRecords / perPage)

  const currentOffset = (currentPage - 1) * perPage

  return (
    <StyledPagination>
      <TextShift>
        <Text as="span" size="small">
          {currentOffset}-{currentOffset + perPage} of {totalRecords}
        </Text>
      </TextShift>
      <PaginationItem
        isDisabled={currentPage === 1}
        onPress={() => onPageChange(currentPage - 1)}
      >
        <Icon name="chevron-left" size="s" />
      </PaginationItem>

      {totalPages <= MAX_PAGES ? (
        <Pages
          displayPages={totalPages}
          currentPage={currentPage}
          onPress={onPageChange}
        />
      ) : (
        <Compact
          currentPage={currentPage}
          totalPages={totalPages}
          onPress={onPageChange}
        />
      )}

      <PaginationItem
        isDisabled={currentPage === totalPages}
        onPress={() => onPageChange(currentPage + 1)}
      >
        <Icon name="chevron-right" size="s" />
      </PaginationItem>
    </StyledPagination>
  )
}
