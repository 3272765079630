export type ColorPrimitive =
  | 'primary100'
  | 'primary400'
  | 'primary600'
  | 'secondary100'
  | 'secondary400'
  | 'secondary600'
  | 'neutral100'
  | 'neutral300'
  | 'neutral400'
  | 'neutral500'
  | 'neutral600'
  | 'info30'
  | 'info100'
  | 'info400'
  | 'warning30'
  | 'warning100'
  | 'warning400'
  | 'success30'
  | 'success100'
  | 'success400'
  | 'alert30'
  | 'alert100'
  | 'alert400'
  | 'white'
  | 'gray70'
  | 'gray80'
  | 'gray90'
  | 'gray100'
  | 'gray200'
  | 'gray300'
  | 'gray400'
  | 'gray500'
  | 'gray600'
  | 'gray700'
  | 'gray800'
  | 'gray900'
  | 'black'
  | 'heart'
  | 'primarySurface'

export type UiColor =
  | 'primary'
  | 'primaryHover'
  | 'primaryActive'
  | 'primaryFocus'
  | 'secondary'
  | 'secondaryHover'
  | 'secondaryActive'
  | 'secondaryFocus'
  | 'neutral'
  | 'neutralHover'
  | 'neutralActive'
  | 'neutralFocus'

export type TShirtSize = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl'
export type Scale =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 10
  | 12
  | 14
  | 16
  | 20
  | 24
  | 32
export type Size = TShirtSize | Scale

export type FontSize =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body'
  | 'bodyLarge'
  | 'bodySmall'
  | 'button'
  | 'buttonText'
  | 'caption'

export type FontWeights = 'regular' | 'medium' | 'semiBold' | 'bold'

export type BorderRadius = 'small' | 'medium' | 'large'

type BoxShadow = 'small' | 'medium' | 'large'

type ZIndex = 'select'

export type Color = ColorPrimitive | UiColor

export const colorPrimitives: Record<ColorPrimitive, string> = {
  primary100: '#fff0f1',
  primary400: '#ff6672',
  primary600: '#f34f5c',
  secondary100: '#e9fbf9',
  secondary400: '#23d3c1',
  secondary600: '#23c9b8',
  neutral100: '#e5effc',
  neutral300: '#6D6A73',
  neutral400: '#1b034f',
  neutral500: '#56525c',
  neutral600: '#110231',
  info30: '#f7f9ff',
  info100: '#e7f0ff',
  info400: '#106cfe',
  warning30: '#fefbf8',
  warning100: '#fdf1e6',
  warning400: '#e77309',
  success30: '#f7fbfa',
  success100: '#e5f2ed',
  success400: '#008050',
  alert30: '#fef8f8',
  alert100: '#fce9ea',
  alert400: '#e4252c',
  white: '#ffffff',
  gray70: '#f6f7f9',
  gray80: '#eceff3',
  gray90: '#e3e7ed',
  gray100: '#dadfe8',
  gray200: '#c6cedc',
  gray300: '#b3bdcf',
  gray400: '#a0adc3',
  gray500: '#8691a7',
  gray600: '#6b7588',
  gray700: '#505869',
  gray800: '#404858',
  gray900: '#323848',
  black: '#0e0f16',
  heart: '#fe2c55',
  primarySurface: '#f5f5f6',
}

export interface Theme {
  colors: Record<Color, string>
  sizes: Record<Size, string>
  fontSizes: Record<FontSize, string>
  fontWeights: Record<FontWeights, string>
  borderRadii: Record<BorderRadius, string>
  boxShadows: Record<BoxShadow, string>
  zIndex: Record<ZIndex, string>
  transitionTime: string
}

export const theme: Theme = {
  colors: {
    ...colorPrimitives,
    primary: colorPrimitives.primary400,
    primaryHover: colorPrimitives.primary600,
    primaryActive: colorPrimitives.primary600,
    primaryFocus: colorPrimitives.primary600,
    secondary: colorPrimitives.secondary400,
    secondaryHover: colorPrimitives.secondary600,
    secondaryActive: colorPrimitives.secondary600,
    secondaryFocus: colorPrimitives.secondary600,
    neutral: colorPrimitives.neutral400,
    neutralHover: colorPrimitives.neutral600,
    neutralActive: colorPrimitives.neutral600,
    neutralFocus: colorPrimitives.neutral600,
  },
  sizes: {
    xxs: '0.25rem',
    xs: '0.5rem',
    s: '1rem',
    m: '1.5rem',
    l: '2rem',
    xl: '2.5rem',
    xxl: '4rem',
    0: '0rem',
    1: '0.25rem',
    2: '0.5rem',
    3: '0.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    10: '2.5rem',
    12: '3rem',
    14: '3.5rem',
    16: '4rem',
    20: '5rem',
    24: '6rem',
    32: '8rem',
  },
  fontSizes: {
    h1: '6.25rem',
    h2: '3.75rem',
    h3: '3rem',
    h4: '2.125rem',
    h5: '1.5rem',
    h6: '1.25rem',
    body: '1rem',
    bodyLarge: '1.125rem',
    bodySmall: '0.875rem',
    button: '0.8125rem',
    buttonText: '0.875rem',
    caption: '0.75rem',
  },
  fontWeights: {
    regular: '400',
    medium: '500',
    semiBold: '600',
    bold: '700',
  },
  borderRadii: {
    small: '0.125rem',
    medium: '0.25rem',
    large: '0.5rem',
  },
  boxShadows: {
    small: '0 0.125rem 1rem rgba(33, 37, 53, 0.08)',
    medium: '0 0.5rem 2rem rgba(33, 37, 53, 0.16)',
    large: '0 2.5rem 4.5rem rgba(33, 37, 53, 0.16)',
  },
  zIndex: {
    select: '100',
  },
  transitionTime: '200ms',
}
