import React from 'react'
import styled from 'styled-components'
import { styledVariant } from '../../util'
import { StyledTextInputProps, TextInputProps } from './text-input.types'
import { inputStatusStyles } from '../../shared/input-status'

export const StyledTextInput = styled.input<StyledTextInputProps>`
  ${styledVariant('$status', inputStatusStyles)};

  ${({ theme }) =>
    `
    box-sizing: border-box;
    width: 100%;
    height: ${theme.sizes[10]};
    padding: 0 ${theme.sizes[4]};
    border-radius: ${theme.borderRadii.medium};
    outline:none;

    font-size:  ${theme.fontSizes.bodySmall};
    color: ${theme.colors.black};

    ::placeholder {
      color: ${theme.colors.gray300};
    }

    &:disabled {
      background-color: ${theme.colors.gray80};
      outline: none;
      border: none;
    }

    &:read-only {
      cursor: not-allowed;
      background-color: ${theme.colors.gray80};
      border-color: ${theme.colors.gray80};

      &:hover {
        outline: none;
        background-color: ${theme.colors.gray80};
        border-color: ${theme.colors.gray80};
      }
    }

    @media only screen and (max-device-width: 600px) {
      -webkit-appearance: none;
      font-size: ${theme.sizes[4]};
    },
  `}
`

export const TextInput = ({ status = 'default', ...props }: TextInputProps) => {
  return <StyledTextInput type="text" {...props} $status={status} />
}
