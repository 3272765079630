import React, { useRef } from 'react'
import { useButton } from '@react-aria/button'
import styled, { css } from 'styled-components'

interface PaginationItemProps {
  children: React.ReactNode
  onPress: VoidFunction
  isActive?: boolean
  isDisabled?: boolean
}

export const StyledPaginationItem = styled.button<{
  $isPressed?: boolean
  $isActive?: boolean
}>`
  ${({ theme, $isPressed, $isActive }) => css`
    width: ${theme.sizes.l};
    height: ${theme.sizes.l};
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: ${theme.borderRadii.small};
    background: transparent;
    transition: all ${theme.transitionTime} ease;
    outline-color: ${theme.colors.primary};
    outline-width: 1px;
    user-select: none;
    font-size: ${theme.fontSizes.buttonText};

    &:hover {
      background: ${theme.colors.gray70};
    }

    &:focus {
      background: ${theme.colors.gray70};
    }

    ${$isPressed &&
    css`
      background: ${theme.colors.primary100};
      color: ${theme.colors.primary400};
    `}

    ${$isActive &&
    css`
      background: ${theme.colors.primary100};
      color: ${theme.colors.primary400};
    `}

    &:disabled {
      opacity: 0.75;
      pointer-events: none;
    }
  `}
`

export const PaginationItem = (props: PaginationItemProps) => {
  const ref = useRef(null)
  const { buttonProps, isPressed } = useButton(props, ref)

  const { children, onPress, isActive } = props

  const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.code === 'Enter' || e.code === 'Space') {
      onPress()
    }
  }

  return (
    <StyledPaginationItem
      {...buttonProps}
      ref={ref}
      onKeyDown={handleKeyDown}
      $isActive={isActive}
      $isPressed={isPressed}
    >
      {children}
    </StyledPaginationItem>
  )
}
