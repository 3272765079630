import React, { useEffect, useState } from 'react'
import styled, { keyframes, css } from 'styled-components'
import { ModalProps, StyledModalProps } from './modal.types'

export const StyledModal = styled.div<StyledModalProps>`
  ${({ theme }) =>
    `
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadii.large};
    box-shadow: 0rem 2.5rem 4.5rem rgba(33, 37, 53, 0.16);
    `}
  ${({ $width }) => $width && `width: ${$width}px;`}
  ${({ $height }) => $height && `height: ${$height}px;`}

  ${({ $isFullscreen }) =>
    $isFullscreen &&
    `
    width:100%;
    height:100%;
    border-radius: unset;
  `}
`

const fadein = keyframes`
  0%{
    opacity:0;
  }
  100%{
    opacity:1;
  }
`

export const StyledModalContainer = styled.dialog<
  Pick<StyledModalProps, '$isFullscreen' | '$blur'>
>`
  ${({ theme, $isFullscreen, $blur }) =>
    css`
      padding: 0px;
      border-radius: ${theme.sizes[2]};
      border-color: transparent;
      &[open] {
        animation: ${fadein} 200ms ease-in;
      }
      ::backdrop {
        background: rgba(14, 15, 22, 0.8);
        ${$blur && 'backdrop-filter: blur(4px);'}
      }
      overflow: visible;

      ${$isFullscreen &&
      `
        width: 100%;
        min-height: 100%;
        max-width: unset;
        outline: none;
        overflow-y: auto;
      `}
    `}
`

export const Modal = ({
  width = 560,
  height,
  open,
  onClose,
  ariaDescribedBy,
  ariaLabelledBy,
  children,
  isFullscreen = false,
  overflowHidden,
  blur = false,
  'data-testid': dataTestId,
}: ModalProps) => {
  const [isModalOpen, setModalOpen] = useState(open)
  const modalRef = React.useRef<HTMLDialogElement>(null)

  const handleCloseModal = () => {
    if (onClose) {
      onClose()
    }
    setModalOpen(false)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === 'Escape') {
      handleCloseModal()
    }
  }

  useEffect(() => {
    setModalOpen(open)
  }, [open])

  useEffect(() => {
    const modalElement = modalRef.current

    if (modalElement) {
      if (isModalOpen) {
        modalElement.showModal()
      } else {
        modalElement.close()
      }
    }
  }, [isModalOpen])

  return (
    <StyledModalContainer
      style={{
        ...(overflowHidden && { overflow: 'hidden' }),
      }}
      ref={modalRef}
      onKeyDown={handleKeyDown}
      className="modal"
      aria-describedby={ariaDescribedBy}
      aria-labelledby={ariaLabelledBy}
      $isFullscreen={isFullscreen}
      $blur={blur}
    >
      <StyledModal
        $width={width}
        $height={height}
        $isFullscreen={isFullscreen}
        data-testid={dataTestId}
      >
        {open && children}
      </StyledModal>
    </StyledModalContainer>
  )
}
