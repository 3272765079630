import React from 'react'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import styles from './AuthSuccessText.module.scss'
import { AuthSuccessTextProps } from './Auth.types'

export function AuthSuccessText({ text, signIn = false }: AuthSuccessTextProps) {
  const { t } = useTranslation()

  return (
    <>
      <Typography color="secondary" variant="body1">
        {text}
      </Typography>
      {!signIn && (
        <Typography color="secondary" variant="body1" className={styles.signInLink}>
          <Link to="/signin">{t('typography.nowSignInHere')}</Link>
        </Typography>
      )}
    </>
  )
}
