import styled from 'styled-components'

import { LogoProps } from './logo.types'
import { logosVariants } from './logo-variants'

const LogoContainer = styled.div`
  width: 160px;
  height: 26.69px;
`

export function Logo({ variant = 'default' }: Readonly<LogoProps>) {
  return <LogoContainer>{logosVariants[variant]}</LogoContainer>
}
