import { Divider } from '@mui/material'
import { Heading } from '../heading'
import { Spacer } from '../spacer'
import { Text } from '../text'
import { Layout } from './Layout/Layout'
import styled from 'styled-components'

import { ReactComponent as MaintenanceFrame } from './../illustrated-message/illustrations/maintenance_frame.svg'
import { ReactComponent as PercentIcon } from './../../assets/images/icons/percent-icon.svg'

export const StyledMaintenancePage = styled.div`
  text-align: center;
  padding: 20px;
  max-width: 560px;
  margin: 0 auto;

  @media (min-width: 600px) : {
    align-items: center;
  }
`

export const StyledMaintenancePageFooter = styled.div`
  margin-top: auto;
  color: #6a6d74;
  display: block;
  text-align: center;
  hr {
    width: auto;
  }

  a {
    color: #6a6d74;
    text-decoration: none;
  }

  p {
    margin: 24px 0;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.3px;
    display: inline-block;
  }

  svg {
    margin-right: 4px;
    margin-bottom: -1px;
  }

  @media (min-width: 600px) {
    align-items: center;
    hr {
      width: 560px;
    }
  }
`

export function MaintenancePage() {
  return (
    <Layout>
      <StyledMaintenancePage>
        <MaintenanceFrame />
        <Heading level="h5">Scheduled maintenance ongoing</Heading>
        <Spacer size={4} axis="vertical" />
        <Text>
          We’re sorry for the inconvenience, but we're currently undergoing
          system improvements and will be back online shortly. We apologize for
          any inconvenience and appreciate your patience.
        </Text>
        <Spacer size={4} axis="vertical" />
        <span style={{ fontWeight: '700' }}>Note: </span>
        <Text>
          If you’ve just taken an action, it will not have gone through
          successfully in our system. Please try again in about an hour or so.
        </Text>
      </StyledMaintenancePage>
      <StyledMaintenancePageFooter>
        <Divider />
        <footer>
          <PercentIcon />
          <p>
            Powered by Percent •{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://poweredbypercent.com/legal/#terms-and-conditions"
            >
              Terms
            </a>{' '}
            •{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://poweredbypercent.com/legal/#privacy-policy"
            >
              Privacy
            </a>
          </p>
        </footer>
      </StyledMaintenancePageFooter>
    </Layout>
  )
}
