import { baseUrl } from '@percent/partner-dashboard/services/apiPaths'
import { PBPListResponse } from '@percent/partner-dashboard/services/types/pbpResponses.types'
import { Employee, GetEmployeesListProps, WorkplaceGivingServiceCapabilities } from './workplaceGivingService.types'

export const workplaceGivingService = ({ percentClient }: WorkplaceGivingServiceCapabilities) => ({
  getEmployeesList: (params: GetEmployeesListProps) => {
    const { filter, ...otherParams } = params

    return percentClient.get<PBPListResponse<Employee>>(`${baseUrl}partner/giving/employees`, {
      params: {
        ...otherParams,
        ...(filter && { filter })
      }
    })
  }
})
