import { ReactComponent as Error404 } from './404.svg'
import { ReactComponent as ExpiredSession } from './expired-session.svg'
import { ReactComponent as GeneralError } from './general-error.svg'
import { ReactComponent as InvalidLink } from './invalid-link.svg'
import { ReactComponent as InvalidSession } from './invalid-session.svg'
import { ReactComponent as NoAccess } from './no-access.svg'
import { ReactComponent as NoDonations } from './no-donations.svg'
import { ReactComponent as NoMilestones } from './no-milestones.svg'
import { ReactComponent as NoPayouts } from './no-payouts.svg'
import { ReactComponent as NoResults } from './no-results.svg'
import { ReactComponent as NothingToSee } from './nothing-to-see.svg'
import { ReactComponent as WrongSettings } from './wrong-settings.svg'
import { ReactComponent as MaintenanceFrame } from './maintenance_frame.svg'
import { ReactComponent as Opportunities } from './opportunities.svg'
export const registry = {
  '404': Error404,
  'expired-session': ExpiredSession,
  'general-error': GeneralError,
  'invalid-link': InvalidLink,
  'invalid-session': InvalidSession,
  'no-access': NoAccess,
  'no-donations': NoDonations,
  'no-milestones': NoMilestones,
  'no-payouts': NoPayouts,
  'no-results': NoResults,
  'nothing-to-see': NothingToSee,
  'wrong-settings': WrongSettings,
  'maintenance-frame': MaintenanceFrame,
  'opportunities-frame': Opportunities,
}
