import React from 'react'
import styled from 'styled-components'
import { ModalTitleProps } from './modal-title.types'

export const StyledModalTitle = styled.div`
  ${({ theme }) =>
    `
    font-weight: ${theme.fontWeights.medium};
    font-size: ${theme.fontSizes.body};
    `}
`

export const ModalTitle = ({ children }: ModalTitleProps) => {
  return <StyledModalTitle>{children}</StyledModalTitle>
}
