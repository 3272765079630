import React from 'react'
import styled from 'styled-components'
import { Pages } from './pages'
import { StyledPaginationItem, PaginationItem } from './pagination-item'

interface CompactProps {
  currentPage: number
  totalPages: number
  onPress: (page: number) => void
}

const StyledEllipsis = styled(StyledPaginationItem)`
  pointer-events: none;
`

const Ellipsis = () => <StyledEllipsis tabIndex={-1}>...</StyledEllipsis>

export const Compact = ({ currentPage, totalPages, onPress }: CompactProps) => {
  if (currentPage > totalPages - 4) {
    return (
      <>
        <PaginationItem isActive={currentPage === 1} onPress={() => onPress(1)}>
          1
        </PaginationItem>

        <Ellipsis />

        <Pages
          displayPages={5}
          currentPage={currentPage}
          enlargement={totalPages - 4}
          onPress={onPress}
        />
      </>
    )
  }

  if (currentPage < 5) {
    return (
      <>
        <Pages displayPages={5} currentPage={currentPage} onPress={onPress} />
        <Ellipsis />
        <PaginationItem
          isActive={currentPage === totalPages}
          onPress={() => onPress(totalPages)}
        >
          {totalPages}
        </PaginationItem>
      </>
    )
  }

  return (
    <>
      <PaginationItem isActive={currentPage === 1} onPress={() => onPress(1)}>
        1
      </PaginationItem>
      <Ellipsis />
      <Pages
        displayPages={3}
        currentPage={currentPage}
        enlargement={currentPage - 1}
        onPress={onPress}
      />
      <Ellipsis />
      <PaginationItem
        isActive={currentPage === totalPages}
        onPress={() => onPress(totalPages)}
      >
        {totalPages}
      </PaginationItem>
    </>
  )
}
