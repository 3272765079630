import React from 'react'
import styled from 'styled-components'
import { IllustratedMessageProps } from './illustrated-message.types'
import { Heading } from '../heading'
import { Text } from '../text'
import { Button } from '../button'
import { registry } from './illustrations'

const StyledIllustratedMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.sizes.s};
`

const IllustrationWrapper = styled.div`
  width: 240px;
  height: 240px;
`

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.sizes.xs};
  max-width: 40rem;
  text-align: center;
`

export const IllustratedMessage = ({
  illustration,
  title,
  description,
  cta,
}: IllustratedMessageProps) => {
  const IllustrationSVG = registry[illustration]

  if (!IllustrationSVG) {
    throw new Error(`Illustration type "${illustration}" is invalid`)
  }

  return (
    <StyledIllustratedMessage>
      <IllustrationWrapper>
        <IllustrationSVG />
      </IllustrationWrapper>

      <ContentSection>
        {title && <Heading level="h5">{title}</Heading>}
        <Text size="medium">{description}</Text>
      </ContentSection>

      {cta ? (
        <Button size="small" onPress={cta.onPress}>
          {cta.label}
        </Button>
      ) : null}
    </StyledIllustratedMessage>
  )
}
