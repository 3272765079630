import { CardDashboard, CardContent, FlagAvatar } from '@percent/lemonade'
import { VerificationOrganisationDetailsCardProps } from './VerificationOrganisationDetailsCard.types'

import { useTranslation } from 'react-i18next'
import { Loader } from '@percent/partner-dashboard/common/components'
import { EligibilityTagsBadges } from './components/EligibilityTagsBadges'
import { OrganisationTypesBadges } from './components/OrganisationTypesBadges'
import { Alpha3Code } from 'i18n-iso-countries'
import styles from './VerificationOrganisationDetails.module.scss'
import { useCountries } from '@percent/partner-dashboard/common/hooks/useCountries/useCountries'

export const VerificationOrganisationDetailsCard = ({
  organisation,
  isLoading,
  validationRequest,
  eligibility,
  hasNoEligibleOrganisationType
}: VerificationOrganisationDetailsCardProps) => {
  const { t } = useTranslation()
  const { getCountryNameByCode } = useCountries()
  const valueOrNotAvailableString = (value?: string | null) => value || t('typography.notAvailable')

  const noAddressData = !organisation?.address && !organisation?.postcode
  const eligibilityNotAvailable = !!validationRequest?.rejectedAt && !hasNoEligibleOrganisationType
  const countryCode = organisation?.countryCode || validationRequest?.countryCode
  const website = organisation?.website || validationRequest?.website

  if (isLoading && !validationRequest) return <Loader />

  return (
    <CardDashboard title={t('typography.organisationDetails')}>
      <CardContent title={t('typography.name')} testId="verification-organisation-name">
        {organisation?.name || validationRequest?.name}
      </CardContent>
      <CardContent title={t('typography.type')} testId="verification-organisation-type">
        <OrganisationTypesBadges
          organisation={organisation}
          validationRequest={validationRequest}
          organisationId={organisation?.id}
        />
      </CardContent>
      <CardContent title={t('typography.website')} testId="verification-organisation-website">
        {website ? (
          <a href={website} target="_blank" rel="noopener noreferrer">
            {website}
          </a>
        ) : (
          t('typography.notAvailable')
        )}
      </CardContent>
      <CardContent title={t('typography.email')} testId="verification-organisation-email">
        {valueOrNotAvailableString(organisation?.email)}
      </CardContent>
      {eligibility && (
        <CardContent title={t('typography.activityTags')} testId="verification-organisation-activity-tags">
          <EligibilityTagsBadges eligibility={eligibility} eligibilityNotAvailable={eligibilityNotAvailable} />
        </CardContent>
      )}
      <CardContent title={t('typography.country')} testId="verification-organisation-country">
        {countryCode ? (
          <div className={styles.countryDetailsWrapper}>
            <FlagAvatar code3={countryCode as Alpha3Code} />
            <span>{valueOrNotAvailableString(getCountryNameByCode(countryCode as Alpha3Code))}</span>
          </div>
        ) : (
          t('typography.notAvailable')
        )}
      </CardContent>
      <CardContent title={t('typography.address')} testId="verification-organisation-address">
        {organisation?.address && <div>{organisation?.address}</div>}
        {organisation?.postcode && <div>{organisation?.postcode}</div>}
        {noAddressData && t('typography.notAvailable')}
      </CardContent>
      <CardContent title={t('typography.systemId')} testId="verification-organisation-system-id">
        {valueOrNotAvailableString(organisation?.id)}
      </CardContent>
    </CardDashboard>
  )
}
